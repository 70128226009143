/* -----------------------------------------------
            CLASSES ADDED BY MARSCRIPT
--------------------------------------------------*/

body {
  overflow: scroll !important;
}

.footer-not-logged{
  width: -webkit-fill-available;
  bottom: 0;
  padding: 0.5em;
  position: absolute;
  background-color: #f2f2f5;

  p{
    margin: 0;
  }
}

.__login-logo {
  width: 100%;
}

.admin-login {
  background: linear-gradient(
      to right,
      rgba(0, 148, 233, 0.4),
      rgba(128, 208, 199, 0.6)
    ),
    url("../images/admin-login-background.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.modal {
  .__login-button {
    border: 1px solid #e8e8e8;
    border-radius: 1em;
    background-color: transparent;
    color: #000;
    padding: 1em;

    &.__notification {
      .mdi {
        font-size: 1.5em;
      }
    }

    &:hover {
      box-shadow: 3px 5px 13px -6px rgba(0, 0, 0, 0.86);
    }

    img {
      width: 15em;
    }
  }
}

.social-buttons {
  ._facebook {
    background-color: #3a5a99;

    &:hover {
      border: 1px solid #3a5a99;
      background-color: #fff;
      color: #3a5a99 !important;
    }
  }

  ._google {
    background-color: #a50000;

    &:hover {
      border: 1px solid #a50000;
      background-color: #fff;
      color: #a50000 !important;
    }
  }
}

.scrollbar-thin .table-responsive {
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 100px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #d4aa70;
    border-radius: 100px;
  }
}

.thin-scrollbar {
  /* width */
  &::-webkit-scrollbar {
    width: 3px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: #e9e9e9;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.card {
  &.campaign-positions {
    box-shadow: 2px 2px 7px 0px rgba(0, 0, 0, 0.41);
    margin-bottom: 5em;

    > .card-body {
      display: flex;
      flex-direction: column;
      gap: 2em;
    }

    .card-text {
      min-height: 10em;
      max-height: 24em;
      overflow: auto;
    }
  }

  &.campaign-experience {
    box-shadow: 1px 1px 6px -1px rgba(0, 0, 0, 0.41);
  }

  &.notification:hover {
    cursor: pointer;
    background-color: #f6f6f6 !important;
  }
}

.__description {
  overflow: auto;
  border: 1px solid lightgray;
  max-height: 10em;
  padding: 2em;
}

.data-view-table {
  .dataTables_scrollHeadInner {
    padding-right: 0 !important;
  }
  :first-child {
    margin-bottom: 0.5em;
  }
  .row:nth-of-type(2) {
    display: none;
  }

  table {
    table-layout: fixed;

    td,
    th {
      overflow: hidden;
      white-space: nowrap;
      -moz-text-overflow: ellipsis;
      -ms-text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      max-width: none;
    }

    th {
      &::after,
      &::before {
        left: auto !important;
        right: 1em !important;
      }
    }
  }
}

._inner-card {
  padding: 1em;
  box-shadow: 1px 1px 6px -1px rgba(0, 0, 0, 0.41);
  margin-bottom: 2em;
}

.upload-image {
  display: block;
  position: absolute;
  left: 11.5em;
  bottom: 5%;
}

/* PROFILE PICTURE CLASSES */
// landscape
.circular--landscape {
  // display: inline-block;
  position: relative;
  width: 10em;
  height: 10em;
  overflow: hidden;
  border-radius: 50%;
  // margin: auto;

  .profile-image {
    width: auto;
    height: 100%;
    margin-left: -50px;
  }
}

//portrait
.circular--portrait {
  position: relative;
  width: 10em;
  height: 10em;
  overflow: hidden;
  border-radius: 50%;
  // margin: auto;

  .profile-image {
    width: 100%;
    height: auto;
    margin: auto;
  }
}

// square
.circular--square .profile-image {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.campaign-description {
  height: 10.3em;
}

.campaign-positions {
  // height: 40em;

  ._description {
    height: 7em;
  }
}

.scroll-y {
  overflow-y: scroll;
}

/************************* AGENT PROFILE *********************/
.agent-document {
  .__file-container {
    height: 15em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      display: block;
      height: 100%;
      object-fit: cover;
    }
  }
}

.agent-skill-list {
  overflow-y: scroll;
  height: 15em;
}

.start-recording {
  height: 6em;
  width: 6em;
  border-radius: 3em;

  i {
    font-size: 2.3em;
  }
}

.recording {
  animation-name: pulse;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes pulse {
  0% {
    box-shadow: 0px 0px 5px 0px rgba(173, 0, 0, 0.3);
  }
  65% {
    box-shadow: 0px 0px 5px 13px rgba(173, 0, 0, 0.3);
  }
  90% {
    box-shadow: 0px 0px 5px 13px rgba(173, 0, 0, 0.6);
  }
}
.video-loading {
  animation-name: videoLoading;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes videoLoading {
  0% {
    box-shadow: 0px 0px 5px 0px rgba(96, 211, 232, 0.3);
  }
  65% {
    box-shadow: 0px 0px 5px 13px rgba(49, 221, 244, 0.3);
  }
  90% {
    box-shadow: 0px 0px 5px 13px rgba(0, 234, 255, 0.6);
  }
}

#user-input-typing-test {
  padding: 1em;
  padding-right: 2em;
  font-size: 2em;
  caret-color: yellow;
  background-color: black;
  color: yellow;
}

.video-test {
  width: 100%;
}

.speed-test-screenshot {
  position: absolute;
  left: 1em;
  bottom: 16%;
  width: 50%;
  padding: 0.5em;
  border: 1px solid darkgray;
  background-color: white;
  box-shadow: 5px 4px 7px 0px rgba(0, 0, 0, 0.49);

  img {
    width: 100%;
    border: 1px solid lightgray;
    margin-bottom: 1em;
  }
}

.stepper {
  .__step {
    &._active::before {
      content: "";
      position: inherit;
      width: 3em;
      height: 3em;
      background-color: rgb(85, 110, 230);
      border-radius: 2em;
      align-self: center;
      margin-bottom: 1em;
    }

    &::before {
      content: "";
      position: inherit;
      width: 3em;
      height: 3em;
      background-color: #ddd;
      border-radius: 2em;
      align-self: center;
      margin-bottom: 1em;
    }
  }
}

.react-tel-input .form-control {
  width: 100% !important;
}

.ql-container {
  &.ql-snow {
    height: 13em;
  }
}
