//
// authentication.scss
//

// authentication home icon
.home-btn {
  position: absolute;
  top: 15px;
  right: 25px;
}

// auth 2

.auth-logo {
  .auth-logo-dark {
    display: $display-block;
  }
  .auth-logo-light {
    display: $display-none;
  }
}

.auth-body-bg {
  background-color: $card-bg;
}

// auth-pass-inputgroup

.auth-pass-inputgroup {
  input[type="input"] + .btn .mdi-eye-outline {
    &:before {
      content: "\F06D1";
    }
  }
}

// authentication full page
.auth-full-bg {
  background-color: rgba($primary, 0.25);
  display: flex;

  background: linear-gradient(
      to top,
      rgba(106, 17, 203, 0.3) 0%,
      rgba(37, 117, 252, 0.3) 100%
    ),
    url("../../../images/login-background-image.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media (min-width: 1200px) {
    height: 100vh;
  }
}

.auth-full-page-content {
  display: flex;

  @media (min-width: 1200px) {
    min-height: 100vh;
  }
}

.auth-review-carousel {
  &.owl-theme {
    .owl-dots {
      .owl-dot {
        span {
          background-color: rgba($primary, 0.25);
        }
        &.active,
        &:hover {
          span {
            background-color: $primary;
          }
        }
      }
    }
  }
}
